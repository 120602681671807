import { Modal as MaterialModal } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { propType as StyledSystemPropType } from '@styled-system/prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Flex } from '../grid';
import { breakpoints } from '../../constants';
import { Divider } from '../divider';
import { ModalButtons } from './modal-buttons';
import { ModalHeader } from './modal-header';
import { P } from '../typography';

const Modal = ({
  backgroundImage,
  backgroundPosition,
  backgroundColor,
  className,
  buttons,
  children,
  handleBack,
  handleClose,
  isDividerHidden,
  hasFooterDivider,
  modalTitle,
  open,
  height,
  width,
  showShadow,
  buttonTitle,
  buttonSecondaryTitle,
  buttonPrice,
  buttonContainerStyle,
  childrenContainerStyle,
  wrapperStyle,
  headerBackgroundColor,
  headerButtonColor,
  footer,
  footerPosition,
  footerBackgroundColor,
  header = (
    <ModalHeader
      modalTitle={modalTitle}
      handleClose={handleClose}
      handleBack={handleBack}
      backgroundColor={headerBackgroundColor}
      buttonColor={headerButtonColor}
    />
  ),
}) => {
  return (
    <StyledMaterialModal open={open} onClose={handleClose}>
      <ModalContent
        className={className}
        position="relative"
        overflow="hidden"
        justifyContent="center"
        data-testid="modal"
        flexDirection="column"
        backgroundColor={backgroundColor}
        backgroundImage={backgroundImage}
        backgroundPosition={backgroundPosition}
        height={height}
        width={width}
        maxHeight={{ _: '100%', lg: '95%' }}
        {...wrapperStyle}
      >
        {header}

        {!isDividerHidden && <Divider pb="4px" variant="lightGrey" />}

        <Box
          flexDirection="column"
          justifyContent="center"
          overflowX="hidden"
          overflowY="auto"
          height="inherit"
          marginBottom={{ _: buttons ? '8rem' : 0, md: 0 }}
          {...childrenContainerStyle}
        >
          {children}
        </Box>

        {hasFooterDivider && <Divider pb="4px" variant="lightGrey" />}

        {(footer || buttons) && (
          <Flex
            pt="12px"
            px="1.5rem"
            flexDirection="column"
            position={footerPosition ?? { _: 'fixed', md: 'relative' }}
            bottom="0"
            width="100%"
            backgroundColor={footerBackgroundColor}
            boxShadow={showShadow ? '0 -2px 10px rgba(0, 0, 0, 0.1)' : undefined}
            zIndex="1"
            pb="max(12px, env(safe-area-inset-bottom))"
            {...buttonContainerStyle}
          >
            {footer || (
              <>
                {buttonTitle && (
                  <Flex pt="2px" pb="12px" width="100%" flexDirection="row" justifyContent="space-between">
                    <Flex>
                      <P variant={3}>{buttonTitle}</P>
                    </Flex>
                    <Flex>
                      <Flex mr="12px">
                        <P variant={3}>{buttonPrice}</P>
                      </Flex>
                      <Flex color="grey500" textTransform="uppercase">
                        <P variant={3}>{buttonSecondaryTitle}</P>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                <Flex flexDirection="column" width="100%" zIndex="100" justifyContent="center">
                  <ModalButtons buttons={buttons} />
                </Flex>
              </>
            )}
          </Flex>
        )}
      </ModalContent>
    </StyledMaterialModal>
  );
};

const StyledMaterialModal = styled(MaterialModal)`
  align-items: center;
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoints.md}) {
    @keyframes easeInAnim {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    animation: easeInAnim 0.2s ease-in-out;
  }
`;

const ModalContent = styled(Flex)`
  outline: none;
  background-image: ${props => (props.backgroundImage ? `url(${props.backgroundImage})` : undefined)};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : undefined)};
  background-position: ${props => (props.backgroundPosition ? props.backgroundPosition : 'center')};
  background-repeat: no-repeat;
  background-size: cover;
`;

Modal.propTypes = {
  buttonPrice: PropTypes.string,
  buttons: PropTypes.shape({
    primary: PropTypes.node.isRequired,
    secondary: PropTypes.node,
  }),
  buttonSecondaryTitle: PropTypes.string,
  buttonTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  handleBack: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  hasFooterDivider: PropTypes.bool,
  height: StyledSystemPropType,
  header: PropTypes.node,
  isDividerHidden: PropTypes.bool,
  modalTitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  showShadow: PropTypes.bool,
  headerBackgroundColor: PropTypes.string,
  headerButtonColor: PropTypes.string,
  footerBackgroundColor: PropTypes.string,
  footerPosition: PropTypes.string,
  backgroundColor: PropTypes.string,
  footer: PropTypes.node,
  width: StyledSystemPropType,
  buttonContainerStyle: StyledSystemPropType,
  childrenContainerStyle: StyledSystemPropType,
  wrapperStyle: StyledSystemPropType,
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.string,
};

Modal.defaultProps = {
  buttonPrice: null,
  buttons: null,
  buttonSecondaryTitle: null,
  buttonTitle: null,
  className: undefined,
  handleBack: null,
  hasFooterDivider: false,
  height: {
    _: '100%',
    md: 'fit-content',
  },
  width: {
    _: '100%',
    md: breakpoints.sm,
  },
  isDividerHidden: false,
  modalTitle: null,
  showShadow: false,
  header: undefined,
  headerBackgroundColor: 'white',
  headerButtonColor: 'greyPrimary',
  backgroundColor: 'white',
  footerBackgroundColor: 'white',
  footerPosition: undefined,
  footer: undefined,
  buttonContainerStyle: null,
  childrenContainerStyle: null,
  wrapperStyle: null,
  backgroundImage: null,
  backgroundPosition: null,
};

export { Modal };
