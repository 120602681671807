import styled from 'styled-components';
import { Box } from '@components/grid';

export const Quantity = styled(Box).attrs(() => ({
  display: 'flex',
  mx: '12px',
  justifyContent: 'center',
  alignItems: 'center',
  borderStyle: 'solid',
  borderColor: 'grey200',
  borderWidth: '1px',
  borderRadius: '4px',
}))`
  user-select: none;
  background-color: white;
`;
