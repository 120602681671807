import { ModalHeaderButton } from '@components/modal-header-button';
import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Flex } from '../../grid';
import { Icon } from '../../icons';
import { Subheading } from '../../typography';

const ModalHeader = ({ modalTitle, handleBack, handleClose, backgroundColor, buttonColor }) => (
  <Flex alignItems="center" justifyContent="center" p={{ _: '1.5rem', md: 1 }} backgroundColor={backgroundColor}>
    <Flex flex={1}>
      {handleBack && (
        <Button title="Back" onClick={handleBack} color={buttonColor}>
          <Icon name="chevron" height="24px" width="24px" fill="currentColor" rotate="90deg" />
        </Button>
      )}
    </Flex>
    <Subheading variant={3} textAlign="center">
      {modalTitle}
    </Subheading>
    <Flex flex={1} justifyContent="flex-end">
      <Button title="Close" onClick={handleClose} color={buttonColor} data-testid="modal-close-button">
        <Icon name="cross" height="32px" width="32px" fill="currentColor" />
      </Button>
    </Flex>
  </Flex>
);

const Button = styled(Box).attrs({ as: 'button', type: 'button' })`
  display: flex;
  align-items: center;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: 0.75;
  }
`;

ModalHeader.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func,
  modalTitle: PropTypes.string,
  backgroundColor: PropTypes.string,
  buttonColor: PropTypes.string,
};

ModalHeader.defaultProps = {
  handleBack: null,
  modalTitle: null,
  backgroundColor: undefined,
  buttonColor: undefined,
};

const ModalFloatingHeader = ({ handleClose, handleBack }) => (
  <>
    {handleBack && (
      <Box position="sticky" top="0px" left="0px" zIndex={2} marginTop="-32px" paddingTop="32px">
        <ModalHeaderButton onClick={handleBack} icon="chevronLeft" align="left" ariaLabel="Back" />
      </Box>
    )}
    <Box position="sticky" top="0px" right="0px" zIndex={2} marginTop="-32px" paddingTop="32px">
      <ModalHeaderButton onClick={handleClose} ariaLabel="Close" />
    </Box>
  </>
);

ModalFloatingHeader.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func,
};

ModalFloatingHeader.defaultProps = {
  handleBack: undefined,
};

export { ModalHeader, ModalFloatingHeader };
