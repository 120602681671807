import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Flex } from '../../grid';

const ModalButtons = ({ buttons }) => {
  const hasSecondaryButton = get(buttons, 'secondary');
  const hasTertiaryButton = get(buttons, 'tertiary');

  return (
    <Flex
      flexWrap={{ _: 'wrap', md: 'nowrap' }}
      gap="1rem"
      margin={hasTertiaryButton && 'auto'}
      flexDirection={hasTertiaryButton ? 'column' : 'row'}
      justifyContent={hasSecondaryButton ? 'space-between' : 'center'}
      width={hasTertiaryButton ? '85%' : '100%'}
    >
      {hasSecondaryButton && <Flex width="100%">{buttons.secondary}</Flex>}
      <Flex width="100%" justifyContent="center">
        {buttons.primary}
      </Flex>
      {hasTertiaryButton && (
        <Flex width="100%" mb="20px">
          {buttons.tertiary}
        </Flex>
      )}
    </Flex>
  );
};

ModalButtons.propTypes = {
  buttons: PropTypes.shape({
    primary: PropTypes.node.isRequired,
    secondary: PropTypes.node,
    tertiary: PropTypes.node,
  }).isRequired,
};

export { ModalButtons };
