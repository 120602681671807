import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from '@components/icons';

const FloatingActionButton = styled.button`
  display: flex;
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  top: ${({ theme }) => theme.space[1]};
  right: ${({ theme, align }) => (align === 'right' ? theme.space[1] : undefined)};
  left: ${({ theme, align }) => (align === 'left' ? theme.space[1] : undefined)};
  z-index: 2;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

const ModalHeaderButton = ({ onClick, icon, align, ariaLabel }) => {
  return (
    <FloatingActionButton align={align} onClick={onClick} aria-label={ariaLabel}>
      <Icon name={icon} height="24px" width="24px" fill="black" />
    </FloatingActionButton>
  );
};

ModalHeaderButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right']),
  ariaLabel: PropTypes.string,
};

ModalHeaderButton.defaultProps = {
  ariaLabel: 'Close',
  icon: 'cross',
  align: 'right',
};

export { ModalHeaderButton };
