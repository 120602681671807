import { PropTypes } from 'prop-types';

import { useFeature } from '../../hooks/use-feature';

const Feature = ({ children, name, placeholder }) => {
  const flag = useFeature(name);

  if (flag) {
    return children;
  }
  if (placeholder) {
    return placeholder;
  }
  return null;
};

Feature.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.node,
};

Feature.defaultProps = {
  placeholder: null,
};

export { Feature };
