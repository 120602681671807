import { propType as StyledSystemPropType } from '@styled-system/prop-types';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Flex } from '../../grid';
import { Icon } from '../../icons';

const QuantityButton = ({ onClick, iconName, disabled, buttonSize, iconSize, hideWhenDisabled, ...otherProps }) => {
  return (
    <Button
      as="button"
      type="button"
      onClick={onClick}
      justifyContent="center"
      alignItems="center"
      disabled={disabled}
      size={buttonSize}
      hideWhenDisabled={hideWhenDisabled}
      {...otherProps}
    >
      <Icon name={iconName} fill="white" size={iconSize} />
    </Button>
  );
};

const Button = styled(Flex)`
  ${({ disabled }) => !disabled && `cursor: pointer;`}
  border-radius: 4px;
  background: ${({ disabled, theme }) => (disabled ? theme.colors.grey300 : theme.colors.black)};
  opacity: ${({ hideWhenDisabled, disabled }) => (disabled && hideWhenDisabled ? 0 : 1)};
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};
`;

QuantityButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  buttonSize: StyledSystemPropType,
  iconSize: StyledSystemPropType,
  hideWhenDisabled: PropTypes.bool,
};

QuantityButton.defaultProps = {
  disabled: false,
  buttonSize: { xs: '44px', md: '55px' },
  iconSize: { xs: '18px', lg: '24px' },
  hideWhenDisabled: false,
};

export { QuantityButton };
