import PropTypes from 'prop-types';
import React from 'react';

import { Box, Flex } from '../grid';
import { InputList } from '../input-list';
import { P, Subheading } from '../typography';

const StylePicker = ({ title, required, hasError, showTitle, ...listProps }) => (
  <Box px="1.5rem">
    <Flex justifyContent="space-between" alignItems="flex-end">
      {showTitle ? <Subheading variant={3}>{title}</Subheading> : null}
      {required && (
        <P color={hasError ? 'redPrimary' : 'greyPrimary'} variant={3}>
          Required
        </P>
      )}
    </Flex>
    <InputList {...listProps} borderBottomWidth="0px" />
  </Box>
);

StylePicker.propTypes = {
  title: PropTypes.string.isRequired,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  showTitle: PropTypes.bool,
};

StylePicker.defaultProps = {
  required: false,
  hasError: false,
  showTitle: true,
};

export { StylePicker };
