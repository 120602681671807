import { propType as StyledSystemPropType } from '@styled-system/prop-types';
import PropTypes from 'prop-types';
import { Quantity } from './quantity';

import { Box } from '../grid';
import { P } from '../typography';
import { QuantityButton } from './quantity-button';

const QuantityPicker = ({
  quantity,
  increment,
  decrement,
  max,
  min,
  buttonSize,
  iconSize,
  hideWhenDisabled,
  ...props
}) => (
  <Box display="flex" {...props}>
    <QuantityButton
      buttonSize={buttonSize}
      iconSize={iconSize}
      iconName="minus"
      onClick={decrement}
      title="Decrease quantity"
      disabled={quantity <= min}
      hideWhenDisabled={quantity <= min && ['min', 'both'].includes(hideWhenDisabled)}
    />
    <Quantity size={buttonSize}>
      <P as="div" variant={1}>
        {quantity}
      </P>
    </Quantity>
    <QuantityButton
      buttonSize={buttonSize}
      iconSize={iconSize}
      iconName="plus"
      onClick={increment}
      title="Increase quantity"
      disabled={quantity >= max}
      hideWhenDisabled={quantity >= max && ['max', 'both'].includes(hideWhenDisabled)}
    />
  </Box>
);

QuantityPicker.propTypes = {
  quantity: PropTypes.number.isRequired,
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  buttonSize: StyledSystemPropType,
  iconSize: StyledSystemPropType,
  hideWhenDisabled: PropTypes.oneOf(['min', 'max', 'both']),
};

QuantityPicker.defaultProps = {
  max: undefined,
  min: 0,
  buttonSize: { xs: '44px', md: '55px' },
  iconSize: { xs: '18px', lg: '24px' },
  hideWhenDisabled: undefined,
};

export { QuantityPicker };
